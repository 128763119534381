<template>
  <div id="standards">
    <b-container class="base-container-x standards-for-schools-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading === true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div v-if="loading === true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
        <div class="row justify-content-center mb-5">
          <h1 class="text-uppercase mb-3">{{ $t('Standards') }}</h1>
          <div class="col-lg-10 text-center">
            <p>{{ $t('Learn more about our minimum standards requirements of each designation and training level.') }}</p>
            <p>{{ $t('Officially Launch from 4th May 2021') }}</p>
          </div>
        </div>
        <b-row>
          <b-col cols="12" lg="6">
            <h3 class="mb-5 text-center">{{ $t('Standards For Schools') }}</h3>
            <hr class="normal"/>
            <template v-for="item in standardsForSchools">
              <router-link :event="item.show ? 'click' : ''" :to="{ name: item.type }" :class="{ disabled: !item.show }"  class="text-primary-dark link" :key="item.type">
              <b-row v-bind:class="{ hide: !item.show }" class="content">
                <b-col cols="1" class="d-flex  align-items-center">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </b-col>
                <b-col cols="7" class="d-flex align-items-center">
                  <h6>{{ item.topic }}</h6>
                </b-col>
                <b-col cols="2" class="d-flex align-items-center">
                  <h6>{{ item.type }}</h6>
                </b-col>
                <b-col cols="2" class="d-flex align-items-center">
                  <b-img class="w-100" :src="PUBLIC_PATH + `img/certificate/${item.type}.png`" />
                </b-col>
              </b-row>
              <hr v-bind:class="{ hide: !item.show }" class="line"/>
              </router-link>
            </template>
          </b-col>
          <b-col cols="12" lg="6" class="">
            <h3 class="mb-5 text-center">{{ $t('Standards For Teachers') }}</h3>
            <hr class="normal"/>
            <template v-for="item in standardsForTeachers">
              <router-link :event="item.show ? 'click' : ''" :to="{ name: 'teachersStandards', hash: '#'+item.type }" :class="{ disabled: !item.show }"  class="text-primary-dark link" :key="item.type">
              <b-row v-bind:class="{ hide: !item.show }" class="content">
                <b-col cols="1" class="d-flex align-items-center">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </b-col>
                <b-col cols="7" class="d-flex align-items-center">
                  <h6>{{ item.topic }}</h6>
                </b-col>
                <b-col cols="2" class="d-flex align-items-center">
                  <h6>{{ item.type }}</h6>
                </b-col>
                <b-col cols="2" class="d-flex align-items-center">
                  <b-img class="w-100" :src="PUBLIC_PATH + `img/certificate/${item.type}.png`" />
                </b-col>
              </b-row>
              <hr v-bind:class="{ hide: !item.show }" class="line"/>
              </router-link>
            </template>
          </b-col>
        </b-row>
        <h3 class="mt-5 mb-5 text-center">{{ $t('Training Hour Standards & Education Levels') }}</h3>
        <b-table-simple caption-top responsive bordered class="table-default">
          <b-thead>
            <b-tr>
              <b-th class="text-center" variant="light" >{{ $t('Additional') }}</b-th>
              <b-th class="text-center" variant="light" >{{ $t('Level 1')}} </b-th>
              <b-th class="text-center" variant="light" >{{ $t('Level 2')}} </b-th>
              <b-th class="text-center" variant="light" >{{ $t('Level 3')}} </b-th>
              <b-th class="text-center" variant="light" >{{ $t('Level 4')}} </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td class="text-center" >{{ $t('50 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('200 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('300 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('500 Hours') }}</b-td>
              <b-td class="text-center" rowspan="2" >{{ $t('1000 Hours') }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-center" >{{ $t('100 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('250 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('400 Hours') }}</b-td>
              <b-td class="text-center" >{{ $t('600 Hours') }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Standards',
  title: ' | Standards',
  data () {
    return {
      loading: true,
      standardsForSchools: [
        {
          topic: this.$t('Registered School of Yoga'),
          type: 'RSY',
          show: true
        },
        {
          topic: 'Experienced Registered Teacher of Yoga',
          type: 'E-RTS',
          show: false
        },
        {
          topic: this.$t('Aerial Yoga School'),
          type: 'AYS',
          show: true
        },
        {
          topic: this.$t('Kids Yoga School'),
          type: 'KYS',
          show: true
        },
        {
          topic: this.$t('Prenatal Yoga School'),
          type: 'PYS',
          show: true
        },
        {
          topic: this.$t('Yoga Therapy School'),
          type: 'YTS',
          show: true
        },
        {
          topic: this.$t('Ayurveda School'),
          type: 'AVS',
          show: true
        },
        {
          topic: this.$t('Yin Yoga School'),
          type: 'YYS',
          show: true
        },
        {
          topic: this.$t('Meditation & Spiritual School'),
          type: 'MSS',
          show: true
        },
        {
          topic: this.$t('Certified Yoga School'),
          type: 'CYS',
          show: true
        },
        {
          topic: this.$t('Exchange Yoga Experience Program'),
          type: 'EYEP',
          show: true
        },
        {
          topic: this.$t('Online Yoga Course Provider'),
          type: 'OYCP',
          show: true
        },
        {
          topic: this.$t('Genuine Yoga Studio'),
          type: 'GYS',
          show: true
        }
      ],
      standardsForTeachers: [
        {
          topic: 'Registered Teacher of Yoga',
          type: 'RTY',
          show: true
        },
        {
          topic: 'Experienced Registered Teacher of Yoga',
          type: 'ERTY',
          show: true
        },
        {
          topic: 'Aerial Yoga Teacher',
          type: 'AYT',
          show: true
        },
        {
          topic: 'Kids Yoga Teacher',
          type: 'KYT',
          show: true
        },
        {
          topic: 'Prenatal Yoga Teacher',
          type: 'PYT',
          show: true
        },
        {
          topic: 'Yoga Therapy Teacher',
          type: 'YTT',
          show: true
        },
        {
          topic: 'Ayurveda Teacher',
          type: 'AVT',
          show: true
        },
        {
          topic: 'Yin Yoga Teacher',
          type: 'YYT',
          show: true
        },
        {
          topic: 'Meditation & Spiritual Teacher',
          type: 'MST',
          show: true
        },
        {
          topic: 'Certified Yoga Teacher',
          type: 'CYT',
          show: true
        },
        {
          topic: 'Exchange Yoga Experience Program',
          type: 'EYEPT',
          show: false
        },
        {
          topic: 'Online Yoga Course Provider',
          type: 'OYCPT',
          show: false
        },
        {
          topic: 'Genuine Yoga Studio',
          type: 'GYS',
          show: false
        }
      ]
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
hr.normal {
  border: 1px solid black;
}
.content.hide {
  opacity: 0;
}
@media screen and (max-width: 767.98px) {
  .content.hide {
    opacity: 0;
    display: none;
  }
  .line.hide {
    opacity: 0;
    display: none;
  }
}
a.disabled {
   pointer-events: none;
   cursor: default;
}
.link:hover {
  color: red!important;
}
</style>
